import React, {useEffect, useState} from 'react';
import "firebase/database";
import firebase from "firebase/app";
import "firebase/storage";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import Icon from "@material-ui/core/Icon";
import Avatar from "@material-ui/core/Avatar";
import {Paper, Typography} from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import {itemTypes} from "../Vault";
import Tooltip from "@material-ui/core/Tooltip";
import copy from "copy-to-clipboard";
import GField from "./GField";
import WMenu, {menuDivider, mii} from "../../widgets/WMenu";
import Util from "../../inc/Util";

let timeUpdatedTimeoutID;

export default function (props) {

    const {id_vault, id_group} = props.match.params;

    const [state, setState] = useState(null);
    const [type, setType] = useState(itemTypes['']);

    useEffect(() => {

        firebase.database()
            .ref('/vault_items/' + id_vault + '/' + id_group)
            .once('value')
            .then(snap => {
                let val = snap.val();
                if (val) {
                    setState(val);
                    setType(itemTypes[val.type] || itemTypes['']);
                    timeUpdatedTimeoutID = setTimeout(() => {
                        timeUpdatedTimeoutID = null;
                        firebase.database()
                            .ref('/vault_items')
                            .child(id_vault)
                            .child(id_group)
                            .child('time_opened')
                            .set(new Date().getTime());
                    }, 2000);
                }
            });

        return () => {
            if (timeUpdatedTimeoutID) {
                clearTimeout(timeUpdatedTimeoutID);
                timeUpdatedTimeoutID = null;
            }
        };
    }, [props.match.params.id_group]);

    function copyToClipboard(content) {
        copy(content);
    }

    function copyAll() {
        let content = state.name;
        if (state.surname) {
            content += '\n';
            content += state.surname;
        }
        content += '\n';
        content += '============================';

        for (let item of state.items) {
            content += '\n';
            if (item.section) {
                content += '\n';
                content += item.value;
                content += '\n';
                content += '----------------------------';
            } else {
                if (item.value) {
                    content += item.name + ': ' + item.value;
                }
            }
        }
        copyToClipboard(content);
    }

    function copySection(index) {
        let content = '';

        for (let i2 in state.items) {
            let o2 = state.items[i2];

            if (i2 < index) {

            } else if (i2 == index) {
                content += o2.value + ' (' + state.name + ')';
                content += '\n';
                content += '----------------------------';

            } else if (!o2.section) {
                if (o2.value) {
                    content += '\n';
                    content += o2.name + ': ' + o2.value;
                }
            } else {
                break;
            }
        }
        copyToClipboard(content);
    }

    function duplicateGroup() {
        let ref = firebase.database().ref('/vault_items/' + id_vault);
        let key = ref.push().key;
        ref.child(key).set({
            ...state,
            name    : 'Copia de ' + state.name,
            favorite: false,
        });
        props.history.push("/vaults/" + id_vault + "/" + key + "/edit");
    }

    function downloadGroup() {
        let groups = [{...state}];
        Util.downloadJSON(groups, state.name);
    }

    function removeGroup() {
        if (window.confirm('¿Estás seguro?')) {
            firebase.database()
                .ref('/vault_items/' + id_vault + '/' + id_group)
                .remove();
            props.history.push("/vaults/" + id_vault);
        }
    }

    return state == null ? (
        <div style={{textAlign: 'center', padding: '40px 0'}}>
            <CircularProgress/>
        </div>
    ) : (
        <Paper style={{padding: 16}}>
            <Grid container spacing={0} className="vault-form vault-form-see group-view">

                <Grid item xs={4}>
                    <Avatar
                        src={state.pic}
                        className="field-avatar"
                        style={{width: 60, height: 60, float: 'right'}}>
                        {!state.pic && <Icon>{type.icon}</Icon>}
                    </Avatar>
                </Grid>
                <Grid item xs={8}>

                    <Typography className="group-name">{state.name}</Typography>

                    {state.favorite ? (
                        <Tooltip title="Quitar de favoritos">
                            <IconButton
                                size="small"
                                onClick={() => {
                                    firebase.database()
                                        .ref('/vault_items/' + id_vault + '/' + id_group)
                                        .child('favorite')
                                        .set(false);
                                    setState({
                                        ...state,
                                        favorite: false,
                                    });
                                }}>
                                <Icon color="secondary">favorite</Icon>
                            </IconButton>
                        </Tooltip>
                    ) : (
                        <Tooltip title="Agregar a favoritos">
                            <IconButton
                                size="small"
                                onClick={() => {
                                    firebase.database()
                                        .ref('/vault_items/' + id_vault + '/' + id_group)
                                        .child('favorite')
                                        .set(true);
                                    setState({
                                        ...state,
                                        favorite: true,
                                    });
                                }}>
                                <Icon>favorite_border</Icon>
                            </IconButton>
                        </Tooltip>
                    )}
                    {' '}
                    <Tooltip title="Modificar">
                        <IconButton
                            size="small"
                            href={"#/vaults/" + id_vault + "/" + id_group + "/edit"}>
                            <Icon>edit</Icon>
                        </IconButton>
                    </Tooltip>
                    {' '}
                    <Tooltip title="Copiar">
                        <IconButton
                            size="small"
                            onClick={() => copyAll()}>
                            <Icon>library_books</Icon>
                        </IconButton>
                    </Tooltip>
                    {' '}
                    <WMenu
                        items={[
                            mii('control_point_duplicate', 'Duplicar', duplicateGroup),
                            mii('cloud_download', 'Descargar', downloadGroup),
                            menuDivider(),
                            mii('delete', 'Eliminar', removeGroup),
                        ]}/>
                </Grid>

                <Grid item xs={12}>
                    {state.items.map((sec, index) => (
                        <GField
                            key={index}
                            field={sec}
                            onCopySection={() => copySection(index)}/>
                    ))}
                </Grid>

                <Grid item xs={4}/>
                <Grid item xs={8}>
                    <Typography className="group-surname">{state.surname}</Typography>
                </Grid>

            </Grid>
        </Paper>
    );
}