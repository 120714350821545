import React from 'react';

import firebase from "firebase/app";
import "firebase/auth";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";

export default function () {
    return (
        <div style={{padding: 20}}>
            <Grid container justify="center">
                <Grid item xs={12} sm={8} md={4} lg={3}>
                    <Paper style={{padding: 20}}>
                        <Button
                            size="large"
                            variant="contained"
                            color="primary"
                            fullWidth
                            onClick={() => {
                                const googleAuthProvider = new firebase.auth.GoogleAuthProvider();
                                firebase.auth().signInWithPopup(googleAuthProvider);
                            }}>
                            Ingresar con Google
                        </Button>
                    </Paper>
                </Grid>
            </Grid>
        </div>
    );
}