import React, {Fragment, useEffect, useState} from 'react';
import "firebase/database";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import firebase from "firebase/app";
import "firebase/storage";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import Icon from "@material-ui/core/Icon";
import Avatar from "@material-ui/core/Avatar";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import {Paper} from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import {itemTypes} from "../Vault";
import Resizer from 'react-image-file-resizer';
import Util from "../../inc/Util";
import ValueType from "../../models/ValueType";
import Nestable from 'react-nestable';
import Group from "../../models/Group";
import WMenu, {MenuItems, mii} from "../../widgets/WMenu";

export default function (props) {

    const {id_vault, id_group} = props.match.params;

    const newItem = (name = '', value = '', section = false, type = ValueType.TEXT) => {
        return {id: Util.rand(), name, value, section, type};
    };

    const newState = (items = []) => {
        return {
            name    : '',
            surname : '',
            type    : props.match.params.item_type || '',
            favorite: false,
            items   : items,
        };
    };

    const [loadingPic, setLoadingPic] = useState(false);
    const [state, setState] = useState(null);
    const [type, setType] = useState(itemTypes['']);
    const [sorting, setSorting] = useState(false);

    useEffect(() => {

        if (id_group) {
            Group.get(id_vault, id_group).then(group => {
                setState(group);
                setType(itemTypes[group.type] || itemTypes['']);
            });
        } else {
            let itemType = props.match.params.item_type || '';
            if (itemType === 'server') {
                setState({
                    ...newState([
                        newItem('', 'SSH', true),
                        newItem('ip', '', false, ValueType.IP),
                        newItem('usuario', 'root', false, ValueType.USERNAME),
                        newItem('clave', Util.makePassword(), false, ValueType.PASSWORD),

                        newItem('', 'MySQL', true),
                        newItem('usuario', 'root', false, ValueType.USERNAME),
                        newItem('clave', Util.makePassword(), false, ValueType.PASSWORD),

                        newItem('', 'FTP', true),
                        newItem('usuario', 'dev01', false, ValueType.USERNAME),
                        newItem('clave', Util.makePassword(), false, ValueType.PASSWORD),
                    ]),
                });
            } else if (itemType === 'login') {
                setState({
                    ...newState([
                        newItem('url', '', false, ValueType.URL),
                        newItem('usuario', '', false, ValueType.USERNAME),
                        newItem('clave', '', false, ValueType.PASSWORD),
                    ])
                });
            } else {
                setState({
                    ...newState([
                        newItem('', '', true),
                        newItem('', '', false),
                        newItem('', '', false),
                    ])
                });
            }
            setType(itemTypes[itemType]);
        }
    }, [props.match.params.id_group, props.match.params.type, props.match.params.item_type]);

    const changed = e => {
        const target = e.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        setState({
            ...state,
            [name]: value
        });
    };


    const fileChangedHandler = (event) => {
        let fileInput = false;
        if (event.target.files[0]) {
            fileInput = true
        }
        if (fileInput) {
            Resizer.imageFileResizer(
                event.target.files[0],
                200,
                200,
                'PNG',
                100,
                0,
                uri => {
                    console.log(uri);

                    let filename = (id_group || Util.rand()) + '.png';
                    let ref = firebase.storage().ref().child(filename);

                    setLoadingPic(true);
                    ref.put(uri).then(snapshot => {
                        console.log('Uploaded a blob or file!', snapshot);

                        snapshot.ref.getDownloadURL().then(downloadURL => {
                            console.log('File available at', downloadURL);
                            setLoadingPic(false);
                            setState({
                                ...state,
                                pic: downloadURL,
                            });
                        });

                    });
                },
                'blob'
            );


        }
    };

    function save() {
        if (!state.name) {
            alert('Ingresa el nombre');
        } else if (state.items.length === 0) {
            alert('Agrega por lo menos una sección');

        } else {
            if (id_group) {
                firebase.database()
                    .ref('/vault_items')
                    .child(id_vault)
                    .child(id_group)
                    .set(state);
                props.history.push("/vaults/" + id_vault + '/' + id_group);
            } else {
                let id_group = firebase.database()
                    .ref('/vault_items')
                    .child(id_vault)
                    .push().key;
                firebase.database()
                    .ref('/vault_items')
                    .child(id_vault)
                    .child(id_group)
                    .set(state);
                props.history.push("/vaults/" + id_vault + '/' + id_group);
            }
        }
    }

    function cancel() {
        if (id_group) {
            props.history.push("/vaults/" + id_vault + '/' + id_group);
        } else {
            props.history.push("/vaults/" + id_vault);
        }
    }

    function buildField({item, index, handler}) {
        return (
            <Grid key={index} container spacing={0}>
                <Grid item xs={1} style={{textAlign: 'right'}}>
                    {sorting ? handler : (
                        <IconButton
                            size="small"
                            tabIndex={-1}
                            onClick={() => {
                                state.items = state.items.filter((o, i) => i !== index);
                                setState({...state});
                            }}>
                            <Icon color="error" fontSize="small">remove_circle</Icon>
                        </IconButton>
                    )}
                </Grid>
                <Grid item xs={3}>
                    {!item.section && (
                        <TextField
                            name="name"
                            placeholder="Nombre"
                            value={item.name}
                            className="field field-name"
                            fullWidth
                            autoComplete="off"
                            margin="dense"
                            variant="outlined"
                            style={{margin: 0}}
                            disabled={sorting}
                            onChange={e => {
                                item.name = e.target.value;
                                setState({
                                    ...state,
                                    items: state.items,
                                });
                            }}
                            inputProps={{
                                style: {textAlign: 'right', color: '#777'},
                            }}/>
                    )}
                </Grid>
                <Grid item xs={7}>
                    <TextField
                        name="value"
                        placeholder={item.section ? 'Sección' : 'Valor'}
                        value={item.value}
                        className={"field " + (item.section ? 'field-section' : 'field-value')}
                        fullWidth
                        autoComplete="off"
                        margin="dense"
                        variant="outlined"
                        disabled={sorting}
                        type={[
                            'password', 'email', 'url', 'color', 'date'
                        ].includes(item.type) && !item.shown ? item.type : 'text'}
                        style={{margin: 0, fontWeight: item.section ? 600 : 400}}
                        onChange={e => {
                            item.value = e.target.value;
                            setState({
                                ...state,
                                items: state.items,
                            });
                        }}
                        onFocus={e => {
                            if (!item.section) {
                                e.target.select();
                            }
                        }}/>
                </Grid>
                <Grid item xs={1}>
                    {!item.section && (
                        <WMenu
                            iconSize="small"
                            disabled={sorting}
                            items={() => {

                                let menu = new MenuItems();

                                if (item.type === ValueType.PASSWORD) {
                                    menu.item('Generar contraseña', () => {
                                        item.value = Util.makePassword();
                                        setState({...state, items: state.items});
                                    }).setIcon('lock');
                                }

                                menu.section('Tipo de dato');

                                for (let vt of ValueType.all) {
                                    menu.item(vt.name, () => {
                                        item.type = vt.id;
                                        setState({...state, items: state.items});
                                    }).setIcon(item.type === vt.id ? 'check_circle' : 'radio_button_unchecked')
                                        .setSelected(item.type === vt.id)
                                        .setDense();
                                }

                                return menu;
                            }}/>
                    )}
                </Grid>
            </Grid>
        );
    }

    return state == null ? (
        <div style={{textAlign: 'center', padding: '40px 0'}}>
            <CircularProgress/>
        </div>
    ) : (
        <Paper style={{padding: '16px 0'}}>
            <Grid container spacing={0} className={'vault-form vault-form-edit'}>

                <Grid item xs={4}>
                    <Avatar
                        src={state.pic}
                        style={{width: 60, height: 60, float: 'right'}}
                        className="field-avatar">
                        {!state.pic && <Icon>{type.icon}</Icon>}
                        <input type="file" onChange={fileChangedHandler}/>
                        {loadingPic && (
                            <div className="avatar-load">
                                <CircularProgress/>
                            </div>
                        )}
                    </Avatar>
                </Grid>
                <Grid item xs={7}>
                    <TextField
                        name="name"
                        placeholder="Título"
                        value={state.name}
                        className="field field-title"
                        autoFocus
                        fullWidth
                        autoComplete="off"
                        variant="outlined"
                        inputProps={{
                            style: {fontWeight: 600, fontSize: 20},
                        }}
                        onChange={changed}/>
                </Grid>
                <Grid item xs={1}/>

                <Grid item xs={12}>

                    {sorting ? (
                        <Nestable
                            items={state.items}
                            maxDepth={0}
                            handler={
                                <IconButton
                                    size="small"
                                    tabIndex={-1}
                                    style={{cursor: 'move'}}>
                                    <Icon fontSize="small">drag_indicator</Icon>
                                </IconButton>
                            }
                            renderItem={buildField}
                            onChange={items => setState({...state, items})}/>
                    ) : state.items.map((item, index) => buildField({item, index}))}

                </Grid>

                <Grid item xs={4}/>
                <Grid item xs={8}>

                    <ButtonGroup
                        size="small"
                        style={{marginTop: 6, marginBottom: 6}}
                        disabled={sorting}>
                        <Button
                            onClick={() => {
                                setState({
                                    ...state,
                                    items: [
                                        ...state.items,
                                        newItem('', '', true),
                                    ],
                                });
                            }}>
                            <Icon fontSize="small">add</Icon> Sección
                        </Button>
                        <Button
                            onClick={() => {
                                setState({
                                    ...state,
                                    items: [
                                        ...state.items,
                                        newItem('', '', false),
                                    ],
                                });
                            }}>
                            <Icon fontSize="small">add</Icon> Campo
                        </Button>
                    </ButtonGroup>

                </Grid>

                <Grid item xs={4}>
                    <div
                        style={{textAlign: 'right', color: '#777', fontSize: '1rem'}}
                        className="notes-title"
                        hidden>
                        notas
                    </div>
                </Grid>
                <Grid item xs={7}>
                    <TextField
                        name="surname"
                        value={state.surname}
                        className="field field-notes"
                        fullWidth
                        multiline
                        disabled={sorting}
                        rows={2}
                        margin="dense"
                        variant="outlined"
                        placeholder="Notas"
                        style={{margin: 0}}
                        onChange={changed}/>
                </Grid>
                <Grid item xs={1}/>

                <Grid item xs={4}/>
                <Grid item xs={8}>
                    <div style={{marginTop: 6}}>
                        {sorting ? (
                            <Button
                                variant="outlined"
                                color="default"
                                onClick={() => setSorting(false)}>
                                <Icon fontSize="small">check</Icon> Listo
                            </Button>
                        ) : <>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={() => save()}>
                                Guardar
                            </Button>
                            {' '}
                            <WMenu
                                disabled={sorting}
                                items={[
                                    mii('sort', 'Ordenar', () => setSorting(true)).setDisabled(sorting),
                                ]}/>
                            {' '}
                            <Button onClick={() => cancel()}>
                                Cancelar
                            </Button>
                        </>}
                    </div>
                </Grid>

            </Grid>
        </Paper>
    );
}