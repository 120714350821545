import React, {useEffect, useState} from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Grid from "@material-ui/core/Grid";
import {Paper} from "@material-ui/core";
import Icon from "@material-ui/core/Icon";
import InputBase from "@material-ui/core/InputBase";
import InputAdornment from "@material-ui/core/InputAdornment";
import firebase from "firebase/app";
import "firebase/database";
import VaultHome from "./VaultHome";
import {Switch, Route} from "react-router-dom"
import CircularProgress from "@material-ui/core/CircularProgress";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import {me} from "../App";
import Box from "@material-ui/core/Box";
import GroupForm from "./group_form/GroupForm";
import GroupView from "./group_view/GroupView";
import WMenu, {MenuItems, mi} from "../widgets/WMenu";
import NavLinkAdapter from "../widgets/NavLinkAdapter";
import VaultImport from "./VaultImport";

export let itemTypes = {
    ''    : {
        icon: 'security',
        name: 'Cualquiera',
    },
    login : {
        icon: 'lock',
        name: 'Inicio de sesión',
    },
    server: {
        icon: 'cloud',
        name: 'Servidor',
    },
};

let vaultItemsRef;

export default function (props) {

    const {id_vault} = props.match.params;

    const [vaults, setVaults] = useState(null);
    const [vaultItems, setVaultItems] = useState(null);

    useEffect(() => {
        firebase.database()
            .ref('/vaults/' + me.id)
            .once('value')
            .then(snap => {
                let items = [];
                snap.forEach(child => {
                    let item = child.val();
                    item.key = child.key;
                    items.push(item);
                });
                setVaults(items);
            });
    }, []);

    useEffect(() => {
        loadVaultItems();
        return () => {
            vaultItemsRef.off('value');
        };
    }, [props.match.params.id_vault]);

    function loadVaultItems() {
        let vaultItemsAll = [];
        setVaultItems(null);

        vaultItemsRef = firebase.database()
            .ref('/vault_items')
            .child(id_vault)
            .orderByChild('time_opened');

        vaultItemsRef.on('value', snapshot => {
            vaultItemsAll = [];
            snapshot.forEach(child => {
                let item = child.val();
                item.key = child.key;
                vaultItemsAll.unshift(item);
            });
            setVaultItems([...vaultItemsAll]);
        });
    }

    return (
        <div>
            <AppBar>
                <Toolbar>
                    <IconButton
                        color="inherit"
                        href="#/">
                        <Icon>arrow_back</Icon>
                    </IconButton>

                    <Typography variant="h6" noWrap>
                        WPass
                    </Typography>

                    <Box align="center" flexGrow={1}/>

                    <WMenu
                        color="inherit"
                        icon="add_circle"
                        iconSize="default"
                        size="medium"
                        tooltip="Agregar elemento"
                        items={() => {
                            let menu = new MenuItems();

                            Object.entries(itemTypes).map(([key, o]) => {
                                menu.item(o.name, () => {
                                    if (key) {
                                        props.history.push("/vaults/" + id_vault + '/form/' + key);
                                    } else {
                                        props.history.push("/vaults/" + id_vault + '/form');
                                    }
                                }).setIcon(o.icon);
                            });

                            menu.divider();

                            menu.item('Importar', () => props.history.push("/vaults/" + id_vault + '/import'))
                                .setIcon('cloud_upload');

                            return menu;
                        }}/>

                    <WMenu
                        color="inherit"
                        icon="account_circle"
                        iconSize="default"
                        size="medium"
                        items={[
                            mi('Cerrar sesión', () => firebase.auth().signOut()),
                        ]}/>

                </Toolbar>
            </AppBar>
            <div style={{padding: 16}}>
                <Grid container spacing={2} style={{marginTop: 64}}>
                    <Grid item xs={12} md={3} lg={2}>
                        <Paper>
                            <List component="nav">

                                {vaults == null ? (
                                    <div style={{textAlign: 'center', padding: '40px 0'}}>
                                        <CircularProgress/>
                                    </div>
                                ) : vaults.map((vault, index) => (
                                    <ListItem
                                        key={vault.key}
                                        button
                                        divider={index < vaults.length - 1}
                                        component={NavLinkAdapter}
                                        activeClassName="Mui-selected"
                                        to={'/vaults/' + vault.key}>
                                        <ListItemIcon style={{minWidth: 40}}>
                                            <Icon>lock</Icon>
                                        </ListItemIcon>
                                        <ListItemText primary={vault.name}/>
                                    </ListItem>
                                ))}

                            </List>
                        </Paper>
                    </Grid>
                    <Grid item xs={12} md={3} lg={3}>
                        <Paper style={{padding: '4px 8px', marginBottom: 16}} hidden>
                            <InputBase
                                fullWidth
                                startAdornment={
                                    <InputAdornment position="start">
                                        <Icon color="disabled">search</Icon>
                                    </InputAdornment>
                                }
                                placeholder="Buscar"/>
                        </Paper>
                        <Paper>
                            <List component="nav">
                                {vaultItems == null ? (
                                    <div style={{textAlign: 'center', padding: '40px 0'}}>
                                        <CircularProgress/>
                                    </div>
                                ) : vaultItems.length === 0 ? (
                                    <div style={{textAlign: 'center', padding: '40px 0'}}>
                                        No hay datos
                                    </div>
                                ) : vaultItems.map((group, index) => (
                                    <ListItem
                                        key={group.key}
                                        dense
                                        button
                                        divider={index < vaultItems.length - 1}
                                        component={NavLinkAdapter}
                                        activeClassName="Mui-selected"
                                        to={"/vaults/" + id_vault + "/" + group.key}>
                                        <ListItemAvatar>
                                            <Avatar
                                                src={group.pic}
                                                style={{background: '#f9f9f9'}}>
                                                {!group.pic && (
                                                    <Icon style={{color: '#999'}}>
                                                        {itemTypes[group.type].icon}
                                                    </Icon>
                                                )}
                                            </Avatar>
                                        </ListItemAvatar>
                                        <ListItemText primary={group.name} secondary={group.surname}/>
                                    </ListItem>
                                ))}
                            </List>
                        </Paper>
                    </Grid>
                    <Grid item xs={12} md={6} lg={7}>

                        <Switch>
                            <Route path="/vaults/:id_vault" component={VaultHome} exact/>
                            <Route path="/vaults/:id_vault/form" component={GroupForm} exact/>
                            <Route path="/vaults/:id_vault/form/:item_type" component={GroupForm} exact/>
                            <Route path="/vaults/:id_vault/import" component={VaultImport} exact/>
                            <Route path="/vaults/:id_vault/:id_group" component={GroupView} exact/>
                            <Route path="/vaults/:id_vault/:id_group/edit" component={GroupForm} exact/>
                        </Switch>

                    </Grid>
                </Grid>
            </div>
        </div>
    );
}