import React from 'react';
import "firebase/database";

export default function () {

    return (
        <div>

        </div>
    );
}