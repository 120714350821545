import moment from "moment";

export default class Util {

    static rand() {
        return Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
    }

    static makePassword() {
        let pwdChars = "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz";
        let pwdLen = 16;
        return Array(pwdLen).fill(pwdChars).map(function (x) {
            return x[Math.floor(Math.random() * x.length)]
        }).join('');
    }

    static ccFormat(value) {
        let parts = value.match(/.{1,4}/g);
        return parts.join(' ');
    }

    static phoneFormat(value) {
        let parts = value.match(/.{1,3}/g);
        return parts.join(' ');
    }

    static async downloadJSON(data, fileName = 'file') {
        const json = JSON.stringify(data);
        const blob = new Blob([json], {type: 'application/json'});
        const href = await URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = href;
        link.download = fileName + '.json';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

}

export function humanDatetime(value) {
    return value ? moment(value).format('DD/MM/YYYY hh:mm A') : '';
}

export function humanDate(value) {
    return value ? moment(value).format('DD/MM/YYYY') : '';
}

export function humanTime(value) {
    return value ? moment(value).format('hh:mm A') : '';
}