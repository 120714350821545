import React, {useEffect, useState} from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Grid from "@material-ui/core/Grid";
import {Paper} from "@material-ui/core";
import Icon from "@material-ui/core/Icon";

import firebase from "firebase/app";
import "firebase/database";
import {AccountCircle} from "@material-ui/icons";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import {me} from "../App";
import {itemTypes} from "./Vault";
import CircularProgress from "@material-ui/core/CircularProgress";

export default function (props) {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);

    const [loading, setLoading] = useState(false);
    const [key, setKey] = useState(null);
    const [state, setState] = useState({
        name   : '',
        surname: '',
    });

    useEffect(() => {
        if (props.match.params.id) {
            setLoading(true);
            firebase.database()
                .ref('/vaults/' + me.id + '/' + props.match.params.id)
                .once('value')
                .then(snap => {
                    setLoading(false);
                    setKey(props.match.params.id);
                    let val = snap.val();
                    if (val) {
                        setState({
                            name   : val.name,
                            surname: val.surname,
                        });
                    }
                });
        } else {
            setKey(null);
            setState({
                name   : '',
                surname: '',
            });
        }
    }, [props.match.params.id]);

    const handleMenu = event => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const changed = e => {
        const target = e.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        setState({
            ...state,
            [name]: value
        });
    };

    const save = () => {
        if (key) {
            firebase.database().ref('/vaults/' + me.id + '/' + key).set(state);
        } else {
            firebase.database().ref('/vaults/' + me.id).push(state);
        }
        props.history.push('/');
    };

    const remove = () => {
        if (window.confirm('¿Estás seguro?')) {
            firebase.database().ref('/vaults/' + me.id + '/' + key).remove();
            props.history.push('/');
        }
    };

    return (
        <div>
            <AppBar>
                <Toolbar>

                    <IconButton
                        color="inherit"
                        href="#/">
                        <Icon>arrow_back</Icon>
                    </IconButton>

                    <Typography variant="h6" noWrap align="center" style={{flexGrow: 1}}>
                        {loading ? '-' : key ? 'Editar bóveda' : 'Nueva bóveda'}
                    </Typography>

                    <IconButton
                        aria-label="account of current user"
                        aria-controls="menu-appbar"
                        aria-haspopup="true"
                        onClick={handleMenu}
                        color="inherit">
                        <AccountCircle/>
                    </IconButton>
                    <Menu
                        id="menu-appbar"
                        anchorEl={anchorEl}
                        anchorOrigin={{
                            vertical  : 'top',
                            horizontal: 'right',
                        }}
                        keepMounted
                        transformOrigin={{
                            vertical  : 'top',
                            horizontal: 'right',
                        }}
                        open={open}
                        onClose={handleClose}>
                        <MenuItem onClick={() => {
                            handleClose();
                            firebase.auth().signOut();
                        }}>
                            Cerrar sesión
                        </MenuItem>
                    </Menu>

                </Toolbar>
            </AppBar>
            <div style={{padding: 16, marginTop: 64}}>
                {loading ? (
                    <div style={{textAlign: 'center', padding: '40px 0'}}>
                        <CircularProgress/>
                    </div>
                ) : (
                    <Grid container justify="center">
                        <Grid item xs={4}>
                            <Paper style={{padding: 16}}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <TextField
                                            name="name"
                                            value={state.name}
                                            onChange={changed}
                                            placeholder="Título"
                                            autoFocus
                                            fullWidth
                                            required
                                            autoComplete="off"
                                            variant="outlined"/>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            name="surname"
                                            value={state.surname}
                                            onChange={changed}
                                            placeholder="Descripción"
                                            fullWidth
                                            multiline
                                            rows={2}
                                            variant="outlined"/>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Button
                                            variant={state.name === '' ? 'outlined' : 'contained'}
                                            color="primary"
                                            fullWidth
                                            size="large"
                                            disabled={state.name === ''}
                                            onClick={save}>
                                            Guardar bóveda
                                        </Button>
                                    </Grid>
                                    {key && (
                                        <Grid item xs={12}>
                                            <Button
                                                color="secondary"
                                                fullWidth
                                                size="small"
                                                disabled={state.name === ''}
                                                onClick={remove}>
                                                Eliminar bóveda
                                            </Button>
                                        </Grid>
                                    )}
                                </Grid>

                            </Paper>
                        </Grid>
                    </Grid>
                )}
            </div>
        </div>
    );
}