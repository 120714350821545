import React, {useEffect, useState} from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Grid from "@material-ui/core/Grid";
import {Paper} from "@material-ui/core";
import Icon from "@material-ui/core/Icon";
import firebase from "firebase/app";
import "firebase/database";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import Button from "@material-ui/core/Button";
import {me} from "../App";
import CircularProgress from "@material-ui/core/CircularProgress";
import WMenu, {mi, mii} from "../widgets/WMenu";
import NavLinkAdapter from "../widgets/NavLinkAdapter";
import Util from "../inc/Util";

let vaultsRef;

export default function (props) {

    const [vaults, setVaults] = useState(null);

    useEffect(() => {
        loadVaults();
        return () => vaultsRef.off('value');
    }, []);

    function loadVaults() {

        vaultsRef = firebase.database()
            .ref('/vaults')
            .child(me.id);

        vaultsRef.on('value', snapshot => {
            let items = [];
            snapshot.forEach(child => {
                let item = child.val();
                item.key = child.key;
                items.push(item);
            });
            setVaults(items);
        });
    }

    function editVault(vault) {
        props.history.push(`vaults/${vault.key}/edit`);
    }

    function downloadVault(vault) {
        firebase.database()
            .ref('/vault_items/' + vault.key)
            .once('value')
            .then(snap => {
                let groups = [];
                snap.forEach(child => {
                    groups.push(child.val());
                });
                Util.downloadJSON(groups, vault.name);
            });
    }

    return (
        <div>
            <AppBar>
                <Toolbar>

                    <Button color="inherit" variant="outlined" href="#/vaults/form">
                        <Icon>add</Icon> Nueva bóveda
                    </Button>

                    <Typography variant="h6" noWrap align="center" style={{flexGrow: 1}}>
                        WPass
                    </Typography>

                    <WMenu
                        color="inherit"
                        icon="account_circle"
                        iconSize="default"
                        size="medium"
                        items={[
                            mi('Cerrar sesión', () => firebase.auth().signOut()),
                        ]}/>

                </Toolbar>
            </AppBar>
            <div style={{padding: 16}}>
                <Grid container style={{marginTop: 64}} justify="center">
                    <Grid item xs={12} md={4}>
                        <Paper>
                            <List component="nav">
                                {vaults == null ? (
                                    <div style={{textAlign: 'center', padding: '40px 0'}}>
                                        <CircularProgress/>
                                    </div>
                                ) : vaults.length == 0 ? (
                                    <div style={{textAlign: 'center', padding: '40px 0'}}>
                                        No hay datos
                                    </div>
                                ) : vaults.map((vault, index) => (
                                    <ListItem
                                        key={vault.key}
                                        button
                                        divider={index < vaults.length - 1}
                                        component={NavLinkAdapter}
                                        activeClassName="Mui-selected"
                                        to={'/vaults/' + vault.key}>
                                        <ListItemIcon style={{minWidth: 40}}>
                                            <Icon>lock</Icon>
                                        </ListItemIcon>
                                        <ListItemText
                                            primary={vault.name}
                                            secondary={vault.surname}/>
                                        <ListItemSecondaryAction>
                                            <WMenu
                                                items={[
                                                    mii('edit', 'Editar', () => editVault(vault)),
                                                    mii('cloud_download', 'Descargar', () => downloadVault(vault)),
                                                ]}/>
                                        </ListItemSecondaryAction>
                                    </ListItem>
                                ))}
                            </List>
                        </Paper>
                    </Grid>
                </Grid>
            </div>
        </div>
    );
}