import firebase from "firebase";

export default class Group {

    constructor(id, name) {

    }

    static get(id_vault, id_group) {

        return new Promise((resolve, reject) => {
            firebase.database()
                .ref(`/vault_items/${id_vault}/${id_group}`)
                .once('value')
                .then(snap => {
                    let val = snap.val();
                    if (val) {

                        val.items = val.items.map((o, i) => {
                            o.id = i;
                            return o;
                        });

                        resolve(val);
                    } else {
                        reject();
                    }
                });
        });

    }

}