export default class ValueType {

    static TEXT = 'text';
    static URL = 'url';
    static EMAIL = 'email';
    static IP = 'ip';
    static USERNAME = 'username';
    static PASSWORD = 'password';
    static COLOR = 'color';
    static DATE = 'date';
    static CREDIT_CARD = 'credit_card';
    static PHONE = 'phone';

    constructor(id, name) {
        this.id = id;
        this.name = name;
    }

    /**
     * @returns {ValueType[]}
     */
    static get all() {
        return [
            new ValueType(ValueType.TEXT, 'Texto'),
            new ValueType(ValueType.URL, 'URL'),
            new ValueType(ValueType.EMAIL, 'Correo electrónico'),
            new ValueType(ValueType.IP, 'Dirección IP'),
            new ValueType(ValueType.USERNAME, 'Usuario'),
            new ValueType(ValueType.PASSWORD, 'Contraseña'),
            new ValueType(ValueType.COLOR, 'Color'),
            new ValueType(ValueType.DATE, 'Fecha'),
            new ValueType(ValueType.CREDIT_CARD, 'Tarjeta de crédito'),
            new ValueType(ValueType.PHONE, 'Teléfono'),
        ];
    }

}