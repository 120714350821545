import React, {useEffect, useState} from 'react';
import "firebase/database";
import "firebase/storage";
import {Paper} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import Icon from "@material-ui/core/Icon";
import ListItemText from "@material-ui/core/ListItemText";
import {itemTypes} from "./Vault";
import ListSubheader from "@material-ui/core/ListSubheader";
import Button from "@material-ui/core/Button";
import firebase from "firebase";

export default function (props) {

    const {id_vault} = props.match.params;

    const [groups, setGroups] = useState(null);
    const [saved, setSaved] = useState(false);

    useEffect(() => {
    });

    function save() {
        for (let group of groups) {
            firebase.database()
                .ref('/vault_items/' + id_vault)
                .push(group);
        }
        setSaved(true);
    }

    return (
        <Paper style={{padding: 16}}>
            <Grid container spacing={2}>

                <Grid item xs={12}>
                    <Typography variant="h6">
                        Importar
                    </Typography>
                </Grid>

                <Grid item xs={12}>
                    <TextField
                        variant="outlined"
                        label="Archivo .json"
                        type="file"
                        onChange={e => {
                            let file = e.target.files[0];
                            if (file) {
                                const reader = new FileReader()
                                reader.onload = async (e) => {
                                    const json = e.target.result;
                                    if (!json) {
                                        alert('Error en archivo');
                                    } else {
                                        let jsonArr = JSON.parse(json);
                                        setSaved(false);
                                        setGroups(jsonArr);
                                    }
                                };
                                reader.readAsText(file)
                            }

                        }}
                        InputLabelProps={{
                            shrink: true,
                        }}/>
                </Grid>

                <Grid item xs={12}>

                    {saved ? (
                        <div style={{padding: 32}}>
                            Guardado correctamente
                        </div>
                    ) : groups == null ? (
                        <div>Selecciona el archivo .json</div>
                    ) : groups.length == 0 ? (
                        <div>
                            No hay grupos
                        </div>
                    ) : (
                        <div>
                            <List
                                component="nav"
                                subheader={
                                    <ListSubheader>
                                        Grupos: {groups.length}
                                    </ListSubheader>
                                }>
                                {groups.map((group, index) => (
                                    <ListItem
                                        key={index}
                                        dense
                                        divider={index < groups.length - 1}>
                                        <ListItemAvatar>
                                            <Avatar
                                                src={group.pic}
                                                style={{background: '#f9f9f9'}}>
                                                {!group.pic && (
                                                    <Icon style={{color: '#999'}}>
                                                        {itemTypes[group.type].icon}
                                                    </Icon>
                                                )}
                                            </Avatar>
                                        </ListItemAvatar>
                                        <ListItemText
                                            primary={group.name}
                                            secondary={"Elementos: " + group.items.length}/>
                                    </ListItem>
                                ))}
                            </List>

                            <Button variant="contained" color="secondary" onClick={save}>
                                Guardar
                            </Button>

                        </div>
                    )}

                </Grid>

            </Grid>
        </Paper>
    );
}