import React from 'react';
import Vault from "./views/Vault";
import {HashRouter, Switch, Route} from "react-router-dom"

import firebase from "firebase/app";
import "firebase/auth";
import config from "./inc/config";
import {
    FirebaseAuthConsumer,
    FirebaseAuthProvider,
    IfFirebaseAuthed,
    IfFirebaseUnAuthed
} from "@react-firebase/auth";
import {FirebaseDatabaseProvider} from "@react-firebase/database";
import Vaults from "./views/Vaults";
import Login from "./views/Login";
import VaultAdd from "./views/VaultAdd";

export let me = {
    id   : '',
    name : '',
    photo: '',
};

export default function () {

    return (
        <HashRouter>
            <FirebaseAuthProvider {...config} firebase={firebase}>
                <FirebaseDatabaseProvider firebase={firebase} {...config}>
                    <FirebaseAuthConsumer>
                        {({isSignedIn, user, providerId}) => {
                            if (isSignedIn) {
                                me = {
                                    id   : user.uid,
                                    name : user.displayName,
                                    photo: user.photoURL,
                                };
                            }
                        }}
                    </FirebaseAuthConsumer>
                    <IfFirebaseUnAuthed>
                        <Login/>
                    </IfFirebaseUnAuthed>
                    <IfFirebaseAuthed>
                        <Switch>
                            <Route path="/" component={Vaults} exact/>
                            <Route path="/vaults/form" component={VaultAdd} exact/>
                            <Route path="/vaults/:id/edit" component={VaultAdd} exact/>
                            <Route path="/vaults/:id_vault" component={Vault}/>
                        </Switch>
                    </IfFirebaseAuthed>
                </FirebaseDatabaseProvider>
            </FirebaseAuthProvider>
        </HashRouter>
    );
}