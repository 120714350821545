import React, {useState} from 'react';
import "firebase/database";
import Button from "@material-ui/core/Button";

import "firebase/storage";
import IconButton from "@material-ui/core/IconButton";
import Icon from "@material-ui/core/Icon";
import Tooltip from "@material-ui/core/Tooltip";
import ValueType from "../../models/ValueType";
import Box from "@material-ui/core/Box";
import PropTypes from 'prop-types';
import Util, {humanDate} from "../../inc/Util";

function W(props) {

    const {field} = props;

    const [shown, setShown] = useState(false); // para contraseña

    return (
        <div className="gfield">
            <div className="gfield-left">
                <div className="field-name">{field.name}</div>
            </div>
            <div className="gfield-right">

                {field.section ? (
                    <Box display="flex">
                        <Box className="field-section" flexGrow={1}>{field.value}</Box>
                        <Box>
                            <Tooltip title="Copiar datos de la sección">
                                <IconButton size="small" onClick={() => props.onCopySection()}>
                                    <Icon fontSize="small">library_books</Icon>
                                </IconButton>
                            </Tooltip>
                        </Box>
                    </Box>
                ) : (
                    <Box display="flex" className="field-right">
                        <Box className="field-value"
                             id={'field-value-'}
                             flexGrow={1}>
                            <div className="text">
                                {(() => {
                                    switch (field.type) {
                                        case ValueType.PASSWORD:
                                            return shown ? field.value : '•••••••••';
                                        case ValueType.COLOR:
                                            return (
                                                <div className="type-color"
                                                     style={{background: field.value}}>
                                                    {field.value}
                                                </div>
                                            );
                                        case ValueType.CREDIT_CARD:
                                            return Util.ccFormat(field.value);
                                        case ValueType.PHONE:
                                            return Util.phoneFormat(field.value);
                                        case ValueType.DATE:
                                            return humanDate(field.value);
                                        default:
                                            return field.value;
                                    }
                                })()}
                            </div>
                        </Box>

                        {field.type === ValueType.PASSWORD && (shown ? (
                            <Box className="field-action">
                                <Button
                                    size="small"
                                    variant="outlined"
                                    onClick={() => setShown(false)}>
                                    <Icon fontSize="small">visibility_off</Icon>
                                </Button>
                            </Box>
                        ) : (
                            <Box className="field-action">
                                <Button
                                    size="small"
                                    variant="outlined"
                                    onClick={() => setShown(true)}>
                                    <Icon fontSize="small">visibility</Icon>
                                </Button>
                            </Box>
                        ))}

                        {field.type === ValueType.URL && (
                            <Box className="field-action">
                                <Button
                                    size="small"
                                    variant="outlined"
                                    href={(field.value.startsWith('http') ? '' : '//') + field.value}
                                    target="_blank">
                                    Abrir
                                </Button>
                            </Box>
                        )}

                        <Box className="field-action">
                            <Button
                                size="small"
                                variant="outlined"
                                onClick={() => navigator.clipboard.writeText(field.value)}>
                                Copiar
                            </Button>
                        </Box>

                    </Box>
                )}

            </div>
        </div>
    );
}

W.propTypes = {
    field        : PropTypes.object.isRequired,
    onCopySection: PropTypes.func.isRequired,
};

W.defaultProps = {};

export default W;